import React, {Fragment, useEffect} from 'react';
import {A, useRoutes} from 'hookrouter';
import Icon from './Icon';

export default function Healthshare() {
    useEffect(() => {
        document.title = "Kira Derksen - HealthShare"
    }, []);

    return (
        <div className="page">
            <section className="intro">
                <div className="container">
                    <h1>HealthShare</h1>
                    <dl>
                        <dt>Tools used:</dt>
                        <dd>React &amp; Redux</dd>
                        <dd>Jest &amp; Enzyme</dd>
                        <dd>Python</dd>
                        <dd>Webpack</dd>
                        <dd>Docker</dd>
                        <dd>Less</dd>
                    </dl>
                </div>
            </section>
            <section>
                <div className="container">
                    <p>
                        HealthShare is a digital health company dedicated to improving access to health information and practitioners.
                        I worked closely with designers across a variety of products to implement new features, functionality and
                        animations. I was a key member in the development of HealthShare's latest product, BetterConsult.
                    </p>
                    <p>
                        I wrote maintainable and web accessible code to meet WAI-ARIA standards and ensure quality by developing
                        and writing unit tests.  Over my career at HealthShare I took on the role of mentor-ing new junior frontend developers
                        to help familiarize them with the codebase.
                    </p>
                </div>
            </section>
        </div>
    )
}
