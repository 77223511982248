import React, {useEffect} from 'react';

export default function Frontage() {
    useEffect(() => {
        document.title = "Kira Derksen - Frontage"
    }, []);

    return (
        <div className="page">
            <section className="intro">
                <div className="container">
                    <h1>Frontage</h1>
                </div>
            </section>
            <section>
                <div className="container">
                    <p>
                        We're Frontage — Interactive designers & developers. Born into the age of the internet, harbouring a habitual respect & understanding of what it takes to craft stellar digital experiences.
                        We reside on the virtual front-lines of design & technology.
                    </p>
                    <a href="https://frontage.io" target="_blank" rel="noopener noreferrer">Visit Frontage &rarr;</a>
                </div>
            </section>
        </div>
    )
}
