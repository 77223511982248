import React, {Fragment, useEffect} from 'react';

import Icon from './Icon';

export default function Montana() {
    useEffect(() => {
        document.title = "Kira Derksen - Montana Construction"
    }, []);

    return (
        <div className="page">
            <section className="intro">
                <div className="container">
                    <h1>Montana Construction</h1>
                    <dl>    
                        <dt>Tools used:</dt>
                        <dd>PHP</dd>
                        <dd>Javascript</dd>
                        <dd>Foundation</dd>
                        <dd>Sass</dd>
                        <dd>Cockpit</dd>
                        <dd>Photoshop &amp; Indesign</dd>
                    </dl>
                </div>
            </section>
            <section>
                <div className="container">
                    <p>
                        Montana Construction is a Saskatoon, SK based construction company that 
                        required a full website rebuild. Incorporating their brand colors of red and black, 
                        I designed and developed a responsive website that was tailored to their needs. 
                        The site is built using Cockpit, an API-driven CMS.
                    </p>
                    <div className="showcase">
                        <img alt="Montana Construction responsive views" src="/img/montana-responsive.png"/>
                        <img alt="Montana Construction gallery page" src="/img/montana-gallery.png"/>
                        <img alt="Montana Construction contact page" src="/img/montana-contact.png"/>
                    </div>
                </div>
            </section>
        </div>
    )
}
