import React, {Fragment, useEffect} from 'react';

import Icon from './Icon';

export default function Comber() {
    useEffect(() => {
        document.title = "Kira Derksen - Comber font"
    }, []);

    return (
       <div className="page">
            <section className="intro">
                <div className="container">
                    <h1>Comber</h1>
                    <dl>    
                        <dt>Tools used:</dt>
                        <dd>Illustrator</dd>
                        <dd>Photoshop</dd>
                    </dl>
                </div>
            </section>
            <section>
                <div className="container">
                    <p>
                        Comber is a custom font created with all uppercase letters. 
                        Inspiration for this font came from geometric shapes found in nature and the 
                        structure of honeycombs. This is where the name 'Comber' was derived from. 
                        Creating a custom font was a lengthy process that involved using Illustrator's pen 
                        tool to draw each and every individual letter. Once all the letters were perfected 
                        I used font forge to build the font. The primary intended use of the font is within 
                        the electronic music industry, which is why it has a bold and geometric punch.
                    </p>
                    <div className="showcase">
                        <img alt="Comber specimen" src="/img/comber-specimen.jpg"/>
                        <img alt="Comber application" src="/img/comber-application.jpg"/>
                    </div>
                </div>
            </section>
        </div>
    )
}