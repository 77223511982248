import React, {Fragment, useEffect} from 'react';

import Icon from './Icon';

export default function MakeUpYourVote() {
    useEffect(() => {
        document.title = "Kira Derksen - Make Up Your Vote"
    }, []);

    return (
        <div className="page">
            <section className="intro">
                <div className="container">
                    <h1>Make Up Your Vote</h1>
                    <dl>    
                        <dt>Tools used:</dt>
                        <dd>Foundation</dd>
                        <dd>Jquery</dd>
                        <dd>Sass</dd>
                        <dd>PHP</dd>
                        <dd>InDesign</dd>
                        <dd>Photoshop</dd>
                    </dl>
                </div>
            </section>
            <section>
                <div className="container">
                    <p>
                        Make up your vote was created with the goal of getting teenagers more 
                        interested in Canadian politics. Users can track what parties they 
                        align with most by creating an account and voting on political 
                        issues. Over the course of two weeks I worked with a group of 4
                        to develop a responsive and functioning site. For this project, 
                        I took on the roll of scrum master and oversaw the progress of 
                        the work. As well as creating wireframes, I developed the user 
                        sign-up/sign-in flow, political parties page and created newsletters.
                    </p>
                    <div className="showcase">
                        <img alt="Make Up Your Vote responsive views" src="/img/vote-responsive.png"/>
                        <img alt="Make Up Your Vote results page" src="/img/vote-results.png"/>
                        <img alt="Make Up Your Vote parties page" src="/img/vote-parties.png"/>
                    </div>
                </div>
            </section>
        </div>
    )
}