import React, {useEffect} from 'react';

import Icon from './Icon';

export default function About() {
    useEffect(() => {
        document.title = "About Kira Derksen - Frontend developer"
    }, []);

    return (
        <div className="page about">
            <section className="intro">
                <div className="container">
                    <img className="profile" src="/img/profile.jpg" alt="Profile of Kira Derksen"/>
                    <h1>Want to get to know me?</h1>
                    <p>
                        I am a Frontend developer with 8+ years experience living in Queenstown, New Zealand. 
                        I graduated with a Diploma of Web Development and 
                        Interactive Design from the Vancouver Institute of Media Arts in 2016. 
                        I have always had an interest in creative technologies and really 
                        enjoy building cool things. Being a web developer was a natural fit for 
                        me, I love the community and pace of change that the internet provides.
                    </p>
                    <p>
                        I have an outgoing and sociable personality and enjoy working
                        in team environments. I will often get lost spending hours working 
                        on new projects, but at the same time value my down time. 
                        I am particularly passionate about web accessibility and have a keen eye for detail. 
                    </p>
                    <p>
                        I enjoy working closely with designers in the team to create pixel perfect applications. 
                        My current skilsets are primarily React & Javascript in general. I have been heavily 
                        involved in agile development and am proficient in Git flow. My proudest personal 
                        achievement was speaking on a panel at Queenswood School for Girls about women in tech. 
                        Feel free to contact me to discuss how I would make a great addition your team.
                    </p>
                </div>
            </section>
            <section>
                <div className="container">
                    <h2>Experience</h2>
                    <h3>
                        Frontend Developer - HealthShare <br/>
                        <span>Sydney, AU | Jan 2017 - Oct 2019</span>
                    </h3>
                    <p>
                        HealthShare is a digital health company dedicated to improving access to health information and practitioners. 
                        I was a key member in the development of HealthShare's latest product, BetterConsult.
                    </p>
                    <ul>
                        <li>
                            Worked closely with designers across a variety of products to implement new features, functionality and animations
                        </li>
                        <li>
                            Wrote maintainable and web accessible code to meet WAI-ARIA standards 
                        </li>
                        <li>
                            Ensured quality by developing and writing unit tests
                        </li>
                        <li>
                            Took on the role of mentoring new junior frontend developers to help familiarize them with the codebase
                        </li>
                        <li>
                            Created frontend development test for new candidates and assisted with the hiring process
                        </li>
                        <li>
                            Tools: React, Redux, Jest, Enzyme, CSS3, HTML5, Less, Javascript, Python, Django
                        </li>
                    </ul>
                    <h3>
                        Web Developer & Designer - Montanta Construction <br/> 
                        <span>Saskatoon, CA | April 2016 - May 2016</span>
                    </h3>
                    <p>
                        Montana Construction is a Saskatoon, SK based construction company that needed a brand new website.
                    </p>
                    <ul>
                        <li>
                            Built using Cockpit, an API-driven CMS
                        </li>
                        <li>
                            Create wireframes and mockups of new design using Photoshop and Indesign
                        </li>
                        <li>
                            Worked closely with the business to create a site that suited their brand and needs
                        </li>
                        <li>
                            Implemented SEO best practices
                        </li>
                        <li>
                            Tools: PHP, Javascript, Photoshop, Indesign, CSS3, HTML5, Sass
                        </li>
                    </ul>
                </div>
            </section>
            <section>
                <div className="container">
                    <h2>Strengths &amp; Skills</h2>
                    <ul>
                        <li>React &amp; Redux</li>
                        <li>Javascript</li>
                        <li>HTML5</li>
                        <li>CSS3</li>
                        <li>Jest &amp; Enzyme</li>
                        <li>CSS animations</li>
                        <li>Web accessibility</li>
                        <li>Sass/Less</li>
                        <li>Git</li>
                        <li>Responsive web design</li>
                        <li>Working independently</li>
                    </ul>
                </div>
            </section>
            <section>
                <div className="container">
                    <h2>Education</h2>
                    <p>
                        <time>Sept 2015 - Aug 2016</time><br/>
                        Vancouver Institute of Media Arts - Diploma of Web Development and Interactive Design.
                    </p>
                    <a href="/pdf/kira-derksen-resume.pdf" download="Kira Derksen Resume">
                        Download my resume &rarr;
                    </a>
                    <div className="social">
                        <a href="/pdf/kira-derksen-resume.pdf" download="Kira Derksen Resume">
                            <Icon name="resume"/>
                        </a>
                        <a href="https://www.linkedin.com/in/kira-derksen" title="Linkedin for Kira Derksen">
                            <Icon name="linkedin"/>
                        </a>
                        <a href="https://github.com/kirld" title="Github for Kira Derksen">
                            <Icon name="github"/>
                        </a>
                    </div>
                </div>
            </section>
       </div>
    );
}
