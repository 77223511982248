import React, {Fragment, useState, useEffect, useRef} from 'react';
import {useSpring, useTransition, animated as a, config} from 'react-spring';
import {A, useRoutes} from 'hookrouter';
import About from '../components/About';
import Home from '../components/Home';
import Icon from '../components/Icon';
import Project from '../components/Project';

require('../style/app.less');

const routes = {
    '/': () => ({
        id: 'home',
        component: <Home />,
    }),
    '/about': () => ({
        id: 'about',
        image: '/img/about-bg.jpg',
        component: <About />,
    }),
    '/projects/:id': ({id}) => ({
        id,
        image: `/img/${id}-bg.jpg`,
        component: <Project uid={id} />
    }),
};

export default function App() {
    const currentRoute = useRoutes(routes);
    const [route, setRoute] = useState( currentRoute );
    const isShowSidePanel = (currentRoute.id !== 'home');
    const sidePanelComponent = route && route.component;

    useEffect(() => {
        if ( currentRoute.id === 'home' ) return;
        setRoute( currentRoute );
    }, [ currentRoute ]);

    const pageBGSpring = useTransition( currentRoute, route => route.id, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
    });

    const pageSpringStyles = useSpring({
        x: isShowSidePanel ? -100 : 0,
        o: isShowSidePanel ? 1 : 1,
        oa: isShowSidePanel ? 1 : 0,
        from: {x: isShowSidePanel ? -65 : -10, o: 0, oa: 0},
        config: {mass: 1.2, tension: 180, friction: 25},
        onRest: () => {
            if ( isShowSidePanel ) return;
        }
    });

    useEffect(() => {
        if(isShowSidePanel) {
            document.body.classList.add('-is-open');
        } else {
            document.body.classList.remove('-is-open');
        }
    }, [isShowSidePanel]);

    return (
        <Fragment>
            <a.main>
                <a.div
                    aria-hidden={isShowSidePanel}
                    className="view"
                    style={{
                        opacity: pageSpringStyles.o,
                        transform: pageSpringStyles.x.interpolate(x => `translate3d(${x}%,0,0)`),
                    }}
                >
                    <Home/>
                </a.div>
                <a.div
                    aria-hidden={!isShowSidePanel}
                    className="view"
                    style={{
                        opacity: pageSpringStyles.oa,
                        transform: pageSpringStyles.x.interpolate(x => `translate3d(${x}%,0,0)`),
                    }}
                >
                    <A aria-label="Close" className="close" href="/">
                       <Icon name="close"/>
                    </A>
                    <div className="viewport">
                        { sidePanelComponent && sidePanelComponent }
                    </div>
                </a.div>
            </a.main>
            <a.div 
                className="bg-side" 
                style={{
                    transform: pageSpringStyles.x.interpolate( x => `translate3d(${x*3}%,0,0)`)
                }}
            >
                { pageBGSpring.map(({ item, props, key }) => {
                    if ( !item.image ) return null;
                    return (
                        <a.div key={ key } className="bg-side-img" style={{
                            ...props,
                            backgroundImage: item.image ? `url(${ item.image })` : 'none',
                            transform: pageSpringStyles.x.interpolate( x => `translate3d(${1-(x*0.65)}%,0,0)`)
                        }} />
                    )
                }) }

            </a.div>
            <a.div 
                className="bg" 
                style={{
                    transform: pageSpringStyles.x.interpolate( x => `translate3d(${x*0.1}%,0,0)`)
                }} 
            />
        </Fragment>
    )
}