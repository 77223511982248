import React, {Fragment, useState} from 'react';
import {A} from 'hookrouter';

import Icon from './Icon';

export default function Home(props) {
	return (
		<div className="page">
			<section className="intro">
				<div className="container">
					<div className="profile">
						<img src="/img/profile.jpg" alt="Profile of Kira Derksen"/>
						<div>
							<h1>
								<b>Hi, I'm Kira.</b> <br/>
								I'm a frontend developer from Canada, <br/>
								living in Queenstown New Zealand.
							</h1>
							<A href="/about">
								Get to know me &rarr;
							</A>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container">
					<h2>Latest projects</h2>
					<A className="panel bg-frontage" href={`/projects/frontage`}>
                        <h3>
                            Frontage
                            <span>Frontend/Founder</span>
                        </h3>
                    </A>
					<A className="panel bg-healthshare" href={`/projects/healthshare`}>
						<h3>
							HealthShare
							<span>Frontend/Python</span>
						</h3>
					</A>
					<A className="panel bg-montana" href="/projects/montana">
						<h3>
							Montana Construction
							<span>Frontend/PHP</span>
						</h3>
					</A>
					<A className="panel bg-comber" href="/projects/comber">
						<h3>
							Comber
							<span>Design</span>
						</h3>
					</A>
					<A className="panel bg-budget" href="/projects/budget">
						<h3>
							Budget App
							<span>Frontend</span>
						</h3>
					</A>
					<A className="panel bg-make-up-your-vote" href="/projects/make-up-your-vote">
						<h3>
							Make Up Your Vote
							<span>Frontend/PHP</span>
						</h3>
					</A>
				</div>
			</section>
			<section>
				<div className="container">
					<h2>About me</h2>
					<p>
						I am a frontend developer living in Queenstown, New Zealand. I
						graduated with a diploma of web development and interactive
						design from the Vancouver Insitute of Media Arts.
					</p>
					<A href="/about">Read more about me &rarr;</A>
				</div>
			</section>
			<section>
				<div className="container">
					<h2>My skills</h2>
					<p>
						Languages and technologies that I am currently working
						with or have worked with in the past.
					</p>
					<ul>
						<li>
							<Icon name="react"/>
							React
						</li>
						<li>
							<Icon name="redux"/>
							Redux
						</li>
						<li>
							<Icon name="less"/>
							Less
						</li>
						<li>
							<Icon name="html"/>
							HTML5
						</li>
						<li>
							<Icon name="css"/>
							CSS3
						</li>
						<li>
						<Icon name="javascript"/>
							Javascript
						</li>
						<li>
							<Icon name="python"/>
							Python
						</li>
					</ul>
					<dl>
						<dt>Past projects included working with</dt>
						<dd>Sass</dd>
						<dd>Foundation</dd>
						<dd>Adobe Suite products</dd>
						<dd>Jquery</dd>
						<dd>Angular</dd>
						<dd>PHP</dd>
						<dd>MySQLi</dd>
						<dd>Wordpress</dd>
					</dl>
					<div className="social">
						<a href="/pdf/kira-derksen-resume.pdf" download="Kira Derksen Resume">
							<Icon name="resume"/>
						</a>
						<a href="https://www.linkedin.com/in/kira-derksen" title="Linkedin for Kira Derksen">
							<Icon name="linkedin"/>
						</a>
						<a href="https://github.com/kirld" title="Github for Kira Derksen">
							<Icon name="github"/>
						</a>
					</div>
				</div>
			</section>
		</div>
	);
}
