import React from 'react';
import Budget from './Budget';
import Comber from './Comber';
import Frontage from './Frontage';
import Healthshare from './Healthshare';
import MakeUpYourVote from './MakeUpYourVote';
import Montana from './Montana';

const projects = {
    'budget': <Budget/>,
    'comber': <Comber/>,
    'frontage': <Frontage />,
    'healthshare': <Healthshare/>,
    'make-up-your-vote': <MakeUpYourVote/>,
    'montana': <Montana/>,
}

export default function Project({uid}) {
    const projectComponent = projects[uid];
    if(!projectComponent) return null;
    return projectComponent;
}
