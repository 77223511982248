import React, {Fragment, useEffect} from 'react';

import Icon from './Icon';

export default function Budget() {
    useEffect(() => {
        document.title = "Kira Derksen - Budget App"
    }, []);

    return (
        <div className="page">
            <section className="intro">
                <div className="container">
                    <h1>Budget App</h1>
                    <dl>    
                        <dt>Tools used:</dt>
                        <dd>Angular</dd>
                        <dd>Sass</dd>
                    </dl>
                </div>
            </section>
            <section>
                <div className="container">
                    <p>
                        Utilizing AngularJS I built a responsive, real time budgeting app. 
                        Users can track where they are spending the most by inputting dollar 
                        amounts and choosing their spending category. The graphing is built using 
                        CSS animations and javascript to calculate the percentage width of the bar. 
                        With the assistance of Angular cookies, the app stores local data enabling 
                        the user to track ongoing spending.
                    </p>
                    <div className="showcase">
                        <img alt="Budget app mobile mock" src="/img/budget-app-mock.png"/>
                    </div>
                </div>
            </section>
        </div>
    )
}